export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Users",
				root: true,
				icon: "fas fa-users",
				page: "user",
				bullet: "dot"
			},
			{
				title: "Academy",
				root: true,
				icon: "fas fa-dumbbell",
				page: "academy",
				bullet: "dot"
			},
			{
				title: "Admin Users",
				root: true,
				icon: "fas fa-id-badge",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Access Groups",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
			{
				title: "Firmware Versions",
				root: true,
				icon: "fas fa-microchip",
				page: "firmware",
				bullet: "dot"
			},
			{
				title: "LOP Data",
				root: true,
				icon: "fas fa-chart-area",
				page: "lop",
				bullet: "dot"
			},
			{
				title: "Devices",
				root: true,
				icon: "fas fa-mobile",
				page: "device",
				bullet: "dot"
			},
			{
				title: "Parameters",
				root: true,
				icon: "fas fa-cogs",
				page: "parameter",
				bullet: "dot"
			},
		]
	}
};
