let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost";
} else {
	url = "https://api.dev-smartcuffs.com";
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/admin",
};
