import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const AcademyPage = lazy(() =>
	import("./academy/AcademyPage")
);

const FirmwarePage = lazy(() =>
	import("./firmware/FirmwarePage")
);

const LopPage = lazy(() =>
	import("./lop/LopPage")
);

const DevicePage = lazy(() =>
	import("./device/DevicePage")
);

const ParameterPage = lazy(() =>
	import("./parameter/ParameterPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/user" component={UserPage} />
				<Route path="/academy" component={AcademyPage} />
				<Route path="/firmware" component={FirmwarePage} />
				<Route path="/lop" component={LopPage} />
				<Route path="/device" component={DevicePage} />
				<Route path="/parameter" component={ParameterPage} />
				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
