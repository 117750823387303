import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";

export function ErrorPage3() {
	return (
		<>
			<div className="kt-grid kt-grid--ver kt-grid--root">
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v3"
					style={{
						backgroundImage: `url(${toAbsoluteUrl(
							"/media/error/bg3.jpg"
						)})`
					}}>
					<div className="kt-error_container">
						<div className="kt-error_number">
							<h1>403</h1>
						</div>
						<p className="kt-error_title kt-font-light">You don't have the necessary permission to <br />access this page.</p>
						<p className="kt-error_subtitle">
							How did you ended up here?
            			</p>
						<p className="kt-error_description">
							Talk to your admin.
            			</p>
					</div>
				</div>
			</div>
		</>
	);
}
